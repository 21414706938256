.button {
  position: relative;
  overflow: hidden;
  &.bg-yellow {
    background-color: var(--ui-colors-yellow);
  }

  &.purple {
    background-color: var(--ui-colors-purple);
    color: #fff;
  }

  .inner {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: "";
    background-color: var(--ui-colors-black);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.2s;
    transform: scaleX(0);
    transform-origin: left;
    z-index: 0;
    border-top-right-radius: 2.5rem;
    border-bottom-right-radius: 2.5rem;
  }

  &:hover {
    color: var(--ui-colors-yellow);
    &:before {
      transform: scaleX(1.2);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:before {
      display: none;
    }

    &:hover {
      color: var(--ui-colors-black);
    }
  }
}
